import React, { useState } from "react";
import "./VersatileHeader.css";
import { VersatileIcon } from "../VersatileIcon/VersatileIcon";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
export default function VersatileHeader() {
  const [colorChange, setColorchange] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const handleNavbar = () => {
    if (!showNav) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <header className={colorChange ? "v-header nav-bgcolor" : "v-header"}>
        <Container>
          <div className="nav-container">
            <Link to={"/"}>{VersatileIcon.NavLogo}</Link>
            <nav className={`versatile-navbar  ${showNav && "show-nav"}`}>
              <a href="/" className="p">
                Home
              </a>
              <a href="https://versatile.best/shop-2" className="p">
                Shop
              </a>
              <a href="https://versatile.best/cart" className="p">
                Cart
              </a>
              <a href="https://discord.gg/JypGGCTrGA" className="p">
                Discord
              </a>
              <a href="https://versatile.best/policies-terms-of-service/" className="p">
                Policies/Terms of service
              </a>
              <a href="https://versatile.best/cart/" className="nav-cart-wrapper">
                {VersatileIcon.Cart}
              </a>
            </nav>
            <button
              className={`hamburger ${showNav && "is-active"}`}
              id="hamburger"
              onClick={handleNavbar}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>
          </div>
        </Container>
      </header>
    </>
  );
}
