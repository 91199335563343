import React from "react";
import "./VersatileBtn.css";
import clsx from "clsx";

const VersatileBtn = ({ text, className, variant }) => {
  return (
    <>
      <button
        className={clsx("btn", className, {
          "btn-white": variant === "white",
          "btn-gradient": variant === "gradient",
          "btn-transparent": variant === "transparent",
        })}
        data-text={text}
      >
        {text}
      </button>
    </>
  );
};

export default VersatileBtn;
