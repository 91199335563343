import React from "react";
import ReactPlayer from 'react-player'

import VersatileBtn from "../../components/VersatileBtn/VersatileBtn";
import { Accordion, Container } from "react-bootstrap";
import LaptopHeroImg from "../../assets/images/cover/hero-laptop-screen.png";
import OurFeatureVideo from "../../assets/video/our-favorite.mp4";
import LaptopHeroGIF from "../../assets/video/hero-laptop-gif.gif";
import RocketIcon from "../../assets/images/icons/rocket.svg";
import UniqueToolsIcon from "../../assets/images/icons/unique-tools.svg";
import LighteningIcon from "../../assets/images/icons/lightening.svg";
import GradientCircle from "../../assets/images/icons/gradient-circle.svg";
import AutomateMaskbg from "../../assets/images/backgrounds/automate-card-bg-mask.png";
import AutomateTaskHeroImg from "../../assets/images/cover/automate-tasks-hero.png";
import QuoteIcon from "../../assets/images/icons/quote.svg";
import LaptopVideoImg from "../../assets/images/cover/laptop-video.png";
import LaptopVideoMaskBg from "../../assets/images/icons/favorite-video-bg-mask.svg";
import CircleSmall from "../../assets/images/icons/circle-sm.svg";
import SpectroImg from "../../assets/images/cover/user-spectro.jpg";
import ArrowWhite from "../../assets/images/icons/arrow-white.svg";
import JaixImg from "../../assets/images/cover/user-jaix.jpg";
import BondImg from "../../assets/images/cover/user-bond.png";
import RaImg from "../../assets/images/cover/user-ra.png";
import EndImg from "../../assets/images/cover/user-end.png";
import PointImg from "../../assets/images/cover/user-point.gif";
import SubImg from "../../assets/images/cover/user-sub.gif";
import JpzImg from "../../assets/images/cover/user-jpz.gif";
import AnanyImg from "../../assets/images/cover/user-anany.gif";


import CircleBigBg from "../../assets/images/backgrounds/circle-big.svg";
import GradientCircleAutomate from "../../assets/images/icons/gradient-circle-automate.svg";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import "./Home.css";
import { VersatileIcon } from "../../components/VersatileIcon/VersatileIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { FavoriteList } from "../../constants/consts";

export const Home = () => {
  return (
    <>
      <section className="versatile-hero space-bottom position-relative">
        <Container>
          <div className="hero-container">
            <img src={ArrowWhite} alt="arrow" className="arrow-white" />
            <div className="hero-img-wrap">
              <div
                className="hero-img-wrap-inner"
                data-aos="fade-up"
                data-aos-duration={850}
              >
                <img
                  src={LaptopHeroGIF}
                  alt="LaptopHeroGIF"
                  className="hero-gif"
                />
                <img src={LaptopHeroImg} alt="laptop-hero" />
              </div>
            </div>
            <h2
              className="fw-700 hero-title"
              data-aos="fade-down"
              data-aos-duration={850}
            >
              Versatile V2 - The only Roblox Botting Tool you need
            </h2>
            <h5
              className="small fw-400 hero-caption"
              data-aos="fade-down"
              data-aos-duration={875}
            >
              The most Versatile Roblox automation tool. Unlock 35+ powerful tools and features for just $19.49
            </h5>
            <div data-aos="fade-down" data-aos-duration={900}>
              <a href="https://versatile.best/product/versatile-1-roblox-multitool/">
              <VersatileBtn
                variant={"white"}
                className={"btn-shadow hero-buy-btn"}
                text="Buy Now"
              />
              </a>
              <a href="https://trello.com/b/nnV6jMK1/versatile-1-roblox-multitool">
              <VersatileBtn
                variant={"transparent"}
                className={"fw-600"}
                text="Feature List"
              />
              </a>
            </div>
            <div
              className="hero-num-wrap"
              data-aos="fade-down"
              data-aos-duration={900}
            >
              <div className="checks-wrap">
                <h3 className="small fw-700 mb-4">80 M+</h3>
                <p className="dim-white text-capitalize fw-500">
                  Checks per Minute
                </p>
              </div>
              <div>
                <h3 className="small fw-700 mb-4">50K+</h3>
                <p className="dim-white text-capitalize fw-500">
                  Sales per Minute
                </p>
              </div>
            </div>
          </div>
          <section className="features space-sm-bottom">
            <Container fluid>
              <Row className="justify-content-between align-items-center">
                <Col lg={5} sm={12}>
                  <div
                    className="position-relative features-title-wrap"
                    data-aos="fade-right"
                    data-aos-duration={800}
                  >
                    <div className="features-arrow">{VersatileIcon.Arrow}</div>
                    <h3 className="fw-600 features-title">
                    Discover our Top Features
                    </h3>
                    <h6 className="small dim-gray fw-400 features-description">
                      

With exclusive features such as a Like Bot, Message Bot, Cookie Refresher & Mass Reporter, Versatile is a unique tool that you won’t come across elsewhere.{" "}
                    </h6>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="d-flex align-items-center features-cards-container justify-content-center">
                    <img
                      src={GradientCircle}
                      alt="gradient-circle"
                      className="gradient-circle"
                    />
                    <div className="features-cards-first">
                      <div
                        className="feature-card-wrap"
                        data-aos="fade-down"
                        data-aos-duration={800}
                      >
                        <div className="feature-card-container">
                          <div className="feature-card-icon-wrap">
                            <img src={RocketIcon} alt="rocket" />
                          </div>
                          <div>
                            <h5 className="fw-500 feature-card-title">
                            Swift Set-Up
                            </h5>
                            <p className="small text-gray fw-400">
                            Thanks to our included shared proxies and cookies, you can dive into nearly all features instantly. With our comprehensive documentation, you'll be up and running in less than 5 minutes.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="feature-card-wrap"
                        data-aos="fade-up"
                        data-aos-duration={800}
                      >
                        <div className="feature-card-container">
                          <div className="feature-card-icon-wrap">
                            <img src={LighteningIcon} alt="lightening" />
                          </div>
                          <div>
                            <h5 className="fw-500 feature-card-title">
                            Unparalleled Speed
                            </h5>
                            <p className="small text-gray fw-400">
                            All tools are multi-threaded, allowing you to experience exceptional performance without the need for a high-end machine.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="features-cards-second">
                      <div
                        className="feature-card-wrap"
                        data-aos="fade-left"
                        data-aos-duration={800}
                      >
                        <div className="feature-card-container">
                          <div className="feature-card-icon-wrap">
                            <img src={UniqueToolsIcon} alt="unique-tools" />
                          </div>
                          <div>
                            <h5 className="fw-500 feature-card-title">
                              30+ Powerful Tools
                            </h5>
                            <p className="small text-gray fw-400">
                            Maximize your botting experience with Versatile, complete with a dedicated and friendly support team to guide you every step of the way.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Container>
      </section>

      {/* Automate Tasks start */}

      <section className="space-top-sm space-bottom-sm automate">
        <Container>
          <div
            className="automate-content-wrap"
            data-aos="zoom-in"
            data-aos-duration={800}
          >
            <h3 className="fw-600 text-capitalize text-center">
              Automate tasks & bot with Versatile
            </h3>
            <h6 className="small fw-400 dim-gray text-center automate-caption">
              With exclusive features such as a Like Bot, Message Bot, Cookie
              Refresher & Mass Reporter, Versatile is a unique tool that you
              won’t come across elsewhere.
            </h6>
          </div>
          <Row className="justify-content-between">
            <Col lg={7} sm={12}>

              <ReactPlayer url="https://versatile.best/wp-content/uploads/2023/02/versatile_showcase.mp4" width="100%" height="100%" controls={true} />
            </Col>
            <Col lg={5} sm={12}>
              <div className="position-relative">
                <img
                  src={AutomateMaskbg}
                  alt="automate-mask"
                  className="automate-mask-bg"
                />
                <img
                  src={GradientCircleAutomate}
                  alt="gradient-circle"
                  className="gradient-circle-automate"
                />
                <div
                  className="automate-card-container red-border"
                  data-aos="fade-left"
                  data-aos-delay={300}
                >
                  <div className="automate-card-wrap">
                    <h4 className="fw-500">Affordable Price</h4>
                    <p className="small text-gray fw-400">
                      If you need a botting tool that does it best, at an
                      afffordable price, our tool is for you. Our shared Cookie & Proxy Pool is worth at least $40 by itself.
                    </p>
                  </div>
                </div>
                <div
                  className="automate-card-container blue-border"
                  data-aos="fade-left"
                  data-aos-delay={450}
                >
                  <div className="automate-card-wrap">
                    <h4 className="fw-500">Comprehensive Feature Set (30+ Tools)</h4>
                    <p className="small text-gray fw-400">
                    Discover the ultimate all-in-one toolkit, featuring fan favorites like Account Generator, Like Bot, Model Sales Bot, Follow Bot, Favorite Bot, and many more – all within a single, seamless program.
                    </p>
                  </div>
                </div>
                <div
                  className="automate-card-container green-border"
                  data-aos="fade-left"
                  data-aos-delay={450}
                >
                  <div className="automate-card-wrap">
                    <h4 className="fw-500">Reliable & Trusted</h4>
                    <p className="small text-gray fw-400">
                    Join our 2500+ satisfied customers and 400+ raving testimonials, as you experience the unparalleled reliability of the largest and longest-running multi-tool for Roblox – Versatile.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Automate Tasks end */}

      {/* Favorite start */}

      <section className="favorite space-top space-bottom">
        <Container>
          <Row
            className="align-items-center"
            data-aos="fade-down"
            data-aos-duration={800}
          >
            <Col md={4} xs={12}>
              <div className="position-relative">
                <div className="favorite-arrow">{VersatileIcon.ArrowSmall}</div>
                <h3 className="fw-600 text-capitalize favorite-title-wrap">
                  Top 6 Reasons to choose Versatile
                </h3>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div>
                <h6 className="small fw-400 dim-gray mb-0 favorite-description">
                Our dedicated team has gone above and beyond to deliver exceptional features, ensuring you receive the best product possible.
                </h6>
              </div>
            </Col>
          </Row>
          <div className="space-top-sm">
            
            <div className="favorite-cards-container">
              {FavoriteList &&
                FavoriteList.map((item, index) => {
                  return (
                    <>
                      <div
                        className="favorite-card-wrapper"
                        data-aos="zoom-in"
                        key={index}
                        data-aos-duration={800}
                      >
                        <div className="favorite-icon-wrapper">
                          <div className="favorite-icon-container">
                            <img src={item.image} alt="card-icon" />
                          </div>
                        </div>
                        <h6 className="small text-gray fw-400 favorite-card-description mb-0">
                          {item.description}
                        </h6>
                      </div>
                    </>
                  );
                })}
            </div>
            
          </div>
          <div>

            <div
              className="laptop-video-img-wrap"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              <img
                src={LaptopVideoMaskBg}
                alt="laptop-bg"
                className="laptop-video-mask"
              />
              <img
                src={CircleBigBg}
                alt="circle-big"
                className="laptop-circle-bg"
              />
              <video
                className="our-feature-video"
                autoPlay
                loop
                playsInline
                muted
                src={OurFeatureVideo}
              ></video>

              <div className="curly-line">{VersatileIcon.CurlyLine}</div>
              <img src={LaptopVideoImg} alt="laptop-frame" />
              
            </div>
            <div class="automate-content-wrap aos-init aos-animate" data-aos="zoom-in" data-aos-duration="800">
          <h3 className="fw-600 text-capitalize text-center">
              Features
            </h3>
            </div>
          </div>
        </Container>
      </section>

      {/* Favorite end */}

      {/* Testimonial start */}

      <section className="space-top-sm space-bottom-sm">
        <Container>
          <Row>
            <Col md={4}>
              <div
                className="testimonial-content-wrap"
                data-aos="fade-right"
                data-aos-duration={500}
                data-aos-delay={400}
              >
                <div className="quote-wrap">
                  <img src={QuoteIcon} alt="quote" />
                </div>
                <h3 className="fw-600 testimonial-title">
                  See What Are The Clients Saying About Us
                </h3>
                <div className="rating-wrapper">
                  <div className="rate-num-wrapper">
                    <h1 className="fw-600">4.7</h1>
                  </div>
                  <div className="star-wrapper">
                    {VersatileIcon.Star} {VersatileIcon.Star}{" "}
                    {VersatileIcon.Star} {VersatileIcon.Star}{" "}
                    {VersatileIcon.Star}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} className="position-relative">
              <div className="navigation-wrap">
                <button className={`testimonial-previous testimonial-nav`}>
                  <ArrowLeft2 size="28" color="currentcolor" />
                </button>
                <button className={`testimonial-next testimonial-nav`}>
                  <ArrowRight2 size="28" color="currentcolor" />
                </button>
              </div>
              <div className="triangle-bg">{VersatileIcon.TriangleBg}</div>
              <img src={CircleSmall} alt="circle" className="circle-sm" />
              <Swiper
                slidesPerView={2}
                grabCursor={true}
                modules={[Navigation, Autoplay]}
                navigation={{
                  prevEl: `.testimonial-previous`,
                  nextEl: `.testimonial-next`,
                }}
                autoplay={{
                  delay: 7500,
                }}
                breakpoints={{
                  3760: {
                    slidesPerView: 1,
                  },
                  8200: {
                    slidesPerView: 4,
                  },
                }}
                className="testimonial-swiper"
              >
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                      I have been using this for a while now like coming up on 5
                      months and I have been quite satisfied with what I'm
                      getting, the modules are robust and quite effective and
                      they provide even free proxy pool which you can use, and
                      their price is affordable and competitive in terms of the
                      modules your getting like instead of paying 10 USD for
                      like 1 or 2 features you get to pay 15 USD for 30
                      features. Furthermore, the support team is amazing as all
                      my problems were solved by the helpers or by the owner
                      himself and their system for support is great too.
                      Overall, 9/10.
                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={SpectroImg} alt="spectro" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">Spectro</h4>
                        <p className="small fw-300">
                          Versatile Buyer/V3rmillion Moderator
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                      Versatile is a very fast tool that meets your needs for
                      all aspects of ROBLOX botting, it ranges from 5k CPM to
                      80M CPM (Group Finder). Their support is also very
                      friendly and helpful. Recommend buying!
                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={JaixImg} alt="jaix" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">Jaix</h4>
                        <p className="small fw-300">
                          Versatile Buyer/Programmer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    Versatile is an apt name for this multitool, as it describes it perfectly. It has a sleek interface, tonnes of functionality, and the owner has done an excellent job of optimising performance. Best ROBLOX multitool available, hands down, and it's from an owner who cares about his buyers. Five stars.
                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={SubImg} alt="SubstantialOOF" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">SubstantialOOF</h4>
                        <p className="small fw-300">
                          Versatile Buyer/Computer Enthusiast
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    I wanted to share my experience with Versatile and how it has helped me with my Roblox botting endeavors. At first, I was hesitant to try it out, but after reading positive reviews in a forum, I decided to give it a shot. And I'm glad I did! The support team and owner were extremely friendly and responsive, which was great since I'm still a newbie in this area. The user interface was also very easy to understand and navigate, making it the best Roblox tool I've ever used. Overall, I highly recommend Versatile to anyone looking to improve their Roblox botting game. Thank you for creating such a wonderful product!
                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={JpzImg} alt="jpz" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">Jpz</h4>
                        <p className="small fw-300">
                          Versatile Buyer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    Versatile is actually a really good tool that helped me get started in the botting community
I've used versatile for many years now and I'm impressed with this tool even after all these years most features still work.
10/10
                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={PointImg} alt="Pointless" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">Pointless</h4>
                        <p className="small fw-300">
                          Versatile Buyer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    Versatile is the best, it is versatile, it is fast, and it is affordable. I have never had such a product in years!

                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={AnanyImg} alt="Ananymoos" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">Ananymoos</h4>
                        <p className="small fw-300">
                          Versatile Buyer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    Starting from reading good reviews about Versatile in a forum, I dared myself to buy it.  It turned out very well.  Friendly and responsive support and owner really helped me who is a newbie in terms of roblox botting.  Simple and easy to understand gui, I think this is the best roblox tool I've ever come across!!

                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={BondImg} alt="MyNameIsBond" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">MyNameIsBond</h4>
                        <p className="small fw-300">
                          Versatile Buyer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    Works beautifully 8.5/10 all features work how they should and are easily explained, my personal favorite is the group finder due to its speeds.

                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={EndImg} alt="End" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">End</h4>
                        <p className="small fw-300">
                          Versatile Buyer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-card-container">
                    <p className="fw-400 text-gray testimonial-card-description">
                    I ♥️ versatile it is so good for botting visits and very optimized and got me 20k visits in only a couple hours. Slashes is very good with support and fixing stuff and helpful. Better than fission in my opinion.
Also free cookie pool and proxy pool!!!
9.5/10
                    </p>
                    <div className="user-info-wrapper">
                      <div className="user-img-wrap">
                        <img src={RaImg} alt="4 RA" />
                      </div>
                      <div>
                        <h4 className="small fw-600 mb-3">4 RA</h4>
                        <p className="small fw-300">
                          Versatile Buyer
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonial end */}

      {/* Subsription start */}

      <section className="space-top-sm space-bottom-sm">
        <Container>
          <h3
            className="text-capitalize fw-600 text-center"
            data-aos="zoom-in"
            data-aos-delay={600}
          >
            Simple Pricing
          </h3>
          <h6
            className="small dim-gray text-center fw-400 subscription-description"
            data-aos="zoom-in"
            data-aos-delay={600}
          >
            Lifetime access to our tool, at a competitive price.{" "}
          </h6>
          <div className="subscription-cards-container">
            <div className="d-flex align-items-end subscription-card-container">
              <div
                className="subscription-card"
                data-aos="fade-right"
                data-aos-delay={600}
              >
                <div className="subscription-title-content">
                  <h4 className="subscription-card-title text-center fw-500">
                    Cookies
                  </h4>
                  <h2 className="small fw-600 text-center subscription-price">
                    $3.00
                  </h2>
                  <h5 className="fw-500 text-gray text-center">per thousand</h5>
                  <a href="https://versatile.best/product/cookies/">
                  <VersatileBtn
                    className="btn subscription-btn"
                    variant={"gradient"}
                    text="Get Started"
                  />
                  </a>
                </div>
                <div className="subscription-card-one-separetor subscription-card-separetor"></div>
                <div className="subscription-card-description">
                  <div className="subscription-point-wrap">
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMark}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        All payment methods accepted
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMark}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Randomized Names
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMark}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        100% Valid
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMark}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Automated System
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMark}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Bot generated
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMark}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Amazing support
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="subscription-card popular-subscription-card"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="popular-badge">
                  <h5 className="small text-center fw-400">Popular</h5>
                </div>
                <div className="subscription-title-content">
                  <h4 className="subscription-card-title text-center fw-400">
                    Versatile
                  </h4>
                  <h2 className="small fw-400 text-center subscription-price">
                    $19.50
                  </h2>
                  <h5 className="fw-500 text-center">lifetime</h5>
                  <a href="https://versatile.best/product/versatile-1-roblox-multitool/">
                  <VersatileBtn
                    className="btn subscription-btn fw-500"
                    variant={"white"}
                    text="Get Started"
                  />
                  </a>
                </div>
                <div className="subscription-card-second-separetor subscription-card-separetor"></div>
                <div className="subscription-card-description">
                  <div className="subscription-point-wrap">
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Comes with cookies (25,000+) & proxies (2,000+)
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        30+ Tools
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Lightning fast
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Detailed documentation
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Lifetime updates
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Amazing support
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="subscription-card"
                data-aos="fade-left"
                data-aos-delay={600}
              >
                <div className="subscription-title-content">
                  <h4 className="subscription-card-title text-center fw-500">
                    Versatile (Crypto)
                  </h4>
                  <h2 className="small fw-600 text-center subscription-price">
                    $19.50
                  </h2>
                  <h5 className="fw-500 text-gray text-center">lifetime</h5>
                  <a href="https://versatile.best/product/versatile-1-roblox-multitool/">
                  <VersatileBtn
                    className="btn subscription-btn"
                    variant={"gradient"}
                    text="Get Started"
                  />
                  </a>
                </div>
                <div className="subscription-card-third-separetor subscription-card-separetor"></div>
                <div className="subscription-card-description subscription-last-card">
                  <div className="subscription-point-wrap">
                  <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Comes with cookies & proxies
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        30+ Tools
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Lightning fast
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Detailed documentation
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Lifetime updates
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      {VersatileIcon.CheckMarkWhite}
                      <h6 className="small fw-400 mb-0 subscription-point">
                        Amazing support
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* Subsription end */}

      {/* FAQ start */}

      <section className="space-top-sm space-bottom-sm">
        <Container>
          <h3
            className="text-capitalize text-center fw-600"
            data-aos="zoom-in"
            data-aos-duration={800}
            data-aos-delay={500}
          >
            Frequently asked questions (FAQ)
          </h3>
          <h6
            className="small fw-400 text-center text-gray faq-title-description"
            data-aos="zoom-in"
            data-aos-duration={800}
            data-aos-delay={500}
          >
Have any more questions? Our support team is here to help you.
          </h6>
          <div data-aos="fade-up" data-aos-duration={800} data-aos-delay={500}>
            <Accordion defaultActiveKey="0" className="faq-accordion" flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Are cookies & proxies included?
                </Accordion.Header>
                <Accordion.Body>
                Yes, you can use them for getting started however we recommend using your own. We supply at least 25,000 cookies (shared by everyone) and 2,000 1 GBPS premium proxies. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Do you support Linux & MacOS?
                </Accordion.Header>
                <Accordion.Body>
                  Versatile is designed to run on Windows, however it is
                  possible to run it on Linux & MacOS via Wine. This is in
                  alpha, most features should be working however we cannot
                  provide a guarantee.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What payment methods do you support?
                </Accordion.Header>
                <Accordion.Body>
                We support PayPal, Cryptocurrency & Card Payments.
We also support Amazon US/UK & Steam US £20/$20 giftcards (go to our discord for that). 
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>What is your refund policy?</Accordion.Header>
                <Accordion.Body>
                Our refund & privacy policy can be seen if you clicked "Policies/Terms of Service" from the menu bar. If you have any further inquiries, feel free to contact support. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Where can I contact your support team?
                </Accordion.Header>
                <Accordion.Body>
                You can contact us via support@versatile.best, however for a quicker response, we strongly recommend to make a support ticket in our Discord server (found in the top menu). 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Where can I see the entire feature list?
                </Accordion.Header>
                <Accordion.Body>
                  Check out the "Features" section of our menu. Alternatively, you can <a href="https://trello.com/b/nnV6jMK1/versatile-1-roblox-multitool">click me</a> for an older more in-depth feature list.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>
      </section>

      {/* FAQ end */}

      {/* Get Started start */}

      <section className="space-top-sm space-bottom-sm get-started position-relative">
        <div className="curly-line-bg">{VersatileIcon.CurlyLineBg}</div>
        <img src={CircleSmall} alt="circle" className="get-start-circle" />
        <Container>
          <h2
            className="small text-capitalize fw-700"
            data-aos="fade-left"
            data-aos-duration={400}
            data-aos-delay={500}
          >
            Get started now.
          </h2>
          <h4
            className="text-capitalize fw-400 dim-white"
            data-aos="fade-left"
            data-aos-duration={400}
            data-aos-delay={500}
          >
            Join over 2500+ buyers, and unlock our powerful tools.
          </h4>
          <div
            className="get-started-btn-wrap"
            data-aos="fade-up"
            data-aos-duration={400}
            data-aos-delay={500}
          >
            <a href='https://versatile.best/shop-2'>
            <VersatileBtn
              className={"btn fw-700"}
              variant={"white"}
              text="Get Started"
            />
            </a>
            <a href ='https://trello.com/b/nnV6jMK1/versatile-1-roblox-multitool'>
            <VersatileBtn
              className={"btn fw-400"}
              variant={"transparent"}
              text="Feature List"
            />
            </a>
          </div>
        </Container>
      </section>
      {/* Get Started end */}
    </>
  );
};
