import EnsureDataIcon from "../assets/images/icons/ensure-data.svg";
import SettingIcon from "../assets/images/icons/setting.svg";
import LighteningSmallIcon from "../assets/images/icons/lightning-sm.svg";
import ThumbUpIcon from "../assets/images/icons/thumb-up.svg";
import FriendlyIcon from "../assets/images/icons/friendly.svg";
import StorageIcon from "../assets/images/icons/storage.svg";
export const FavoriteList = [
  {
    image: EnsureDataIcon,
    description:
      "Enhanced Security: Our hashed account and HWID whitelist system guarantee the utmost protection for your data.",
  },
  {
    image: SettingIcon,
    description:
      "Ultimate Customization: Personalize your experience using our config file, allowing you to choose unique modes and speeds for each tool.",
  },
  {
    image: ThumbUpIcon,
    description:
      "Unrivaled Performance: Each tool in Versatile is designed for speed, efficiency, and quality, offering unrivaled performance in the market.",
  },
  {
    image: FriendlyIcon,
    description:
      "Expert Support: Our approachable and knowledgeable support team is always available to assist you and provide valuable advice throughout your journey.",
  },
  {
    image: LighteningSmallIcon,
    description:
      "Lightning Fast: Our Model Sales Bot and Cookie Checker are not only top-of-the-line but also the fastest publicly available options.",
  },
  {
    image: StorageIcon,
    description: "Shared Pool: Unlock the power of 2,000+ high-speed premium proxies and access over 25,000 shared cookies through our shared pool. All enabled in just a few simple clicks.",
  },
];
