import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import VersatileHeader from "./components/VersatileHeader/VersatileHeader";
import VersatileFooter from "./components/VersatileFooter/VersatileFooter";
import { Home } from "./pages/Home/Home";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <BrowserRouter>
      <VersatileHeader />
      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
        </Routes>
      </main>
      <VersatileFooter />
    </BrowserRouter>
  );
}

export default App;
