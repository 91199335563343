import React from "react";
import "./VersatileFooter.css";
import LogoFooter from "../../assets/images/icons/logo-footer.svg";
import { Container } from "react-bootstrap";
import { VersatileIcon } from "../VersatileIcon/VersatileIcon";
import VersatileBtn from "../VersatileBtn/VersatileBtn";

export default function VersatileHeader() {
  return (
    <>
      <section className="space-top-sm space-bottom-sm">
        <Container>
          <div className="footer-container">
            <div className="footer-item-logo footer-item">
              <div>
                <a href="/" className="footer-logo-link">
                  <img
                    src={LogoFooter}
                    alt="versatile"
                    className="w-100 h-100"
                  />
                </a>
              </div>
              <h6 className="small fw-400 text-capitalize dim-gray footer-description">
                The most powerful & Versatile multi-tool.
              </h6>
            </div>
            <div className="footer-item-contact footer-item">
              <h6 className="small footer-title contact-footer-title fw-500">
                Contact
              </h6>
              <div>
                <div className="footer-item-wrapper">
                  <div className="footer-circle">{VersatileIcon.Phone}</div>
                  <a
                    href="tel:912121212"
                    className="p footer-text text-gray fw-400 mb-0"
                  >
                    Join our Discord server
                  </a>
                </div>
                <div className="footer-item-wrapper">
                  <div className="footer-circle">{VersatileIcon.Mail}</div>
                  <a
                    href="mailto:info@versatile.com"
                    className="p footer-text text-gray fw-400 mb-0"
                  >
                    support@versatile.best
                  </a>
                </div>

              </div>
            </div>
            <div className="general-items-wrap footer-item-general footer-item">
              <h6 className="small footer-title fw-500">General</h6>
              <div className="general-link-wrap">
                <a href="/" className="p footer-text text-gray fw-400">
                  Home
                </a>
                <a href="https://versatile.best/shop-2" className="p footer-text text-gray fw-400">
                  Shop
                </a>
                <a href="https://versatile.best/cart" className="p footer-text text-gray fw-400">
                  Cart
                </a>
                <a href="https://discord.gg/JypGGCTrGA" className="p footer-text text-gray fw-400">
                  Discord
                </a>
                <a href="" className="p footer-text text-gray fw-400">
                  Policies
                </a>
              </div>
            </div>
            <div className="footer-item-letter footer-item">
              <h6 className="small footer-title fw-500">Discord Server</h6>
              <h6 className="small fw-400 dim-gray get-letter-description">
                Join our Discord server to always receive the latest updates.
              </h6>
              <a href="https://discord.gg/JypGGCTrGA">
              <VersatileBtn
                variant={"gradient"}
                className={"btn request-btn"}
                text="Join now"
              />
              </a>

            </div>
          </div>
        </Container>
      </section>
      <div className="copyright-footer">
        <p className="small text-capitalize fw-400 text-center text-gray mb-0">
          Copyright © 2023 All Right Of Reserved
        </p>
      </div>
    </>
  );
}
